<template>
  <div class="handle">
    <dialogDiyNew
      :isShow="isShowHandle"
      :dialogDiyData="dialogDelDiyData"
      @dialogEvent="dialogDiyEvent"
    >
      <div slot="dialogContent" class="dia_opera">
        <img class="errIcon" src="@/assets/img/warn.png" alt="" />
        <p>{{$props.handleText}}</p>
        <slot name="handle_btn"></slot>
      </div>
    </dialogDiyNew>
  </div>
</template>

<script>
import dialogDiyNew from '@/components/commonModule/dialogDiyNew'
export default {
  name: 'handle',
  props: ['isShow', 'handleText'],
  components: {
    dialogDiyNew
  },
  data() {
    return {
      isShowHandle: false,
      dialogDelDiyData: {
        title: null,
        sizeStyle: {width: '400px',height: '234px'}
      },
    }
  },
  watch: {
    isShow: {
      immediate: true,
      handler(newVal, oldVal) {
        this.isShowHandle = newVal;
      }
    },
  },
  methods: {
    dialogDiyEvent(val) {
      this.$emit('handlesEvent', val);
    }
  }
}
</script>

<style lang='less'>
  .dia_opera {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    padding: 30px;
    height: 100%;
    & > p {
      text-align: center;
    }
    .errIcon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    .el-button {
      width: 70px;
      height: 34px;
      font-size: 15px;
      padding: 0;
    }
  }
</style>